import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import { debounce } from "lodash"; // Import debounce from lodash
import { Tag, TagCreateInput, TagUpdateInput } from "../../@generated/graphql";
import { DELETE_MANY_TAGS, GET_TAGS } from "./services/tags.gql";
import TagEdit from "./tag.edit.component";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControl,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

function TagsList() {
  const [filterParams, setFilterParams] = useState<{
    page: number;
    perPage: number;
    searchTerm: string;
    type: string;
    archived: boolean | null;
  }>({
    page: 1,
    perPage: 25,
    searchTerm: "",
    type: "",
    archived: null
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<
    TagUpdateInput | TagCreateInput | undefined
  >();

  const { loading, error, data, refetch } = useQuery(GET_TAGS, {
    variables: {
      filter: filterParams
    }
  });

  const [deleteManyTags, { loading: deleteProcessing, error: deleteError }] =
    useMutation(DELETE_MANY_TAGS, {
      refetchQueries: [GET_TAGS]
    });

  const authUser = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 500),
    [refetch]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setFilterParams({
      ...filterParams,
      searchTerm
    });
    debouncedRefetch();
  };

  const handleDelete = () => {
    deleteManyTags({
      variables: {
        data: {
          ids: rowSelectionModel as string[]
        }
      }
    }).then(() => setRowSelectionModel([]));
  };

  const onCreateNew = () => {
    const newTag: TagCreateInput = {
      clientId: authUser?.client as string,
      name: "",
      type: "",
      createdBy: authUser?.id ?? authUser.uid,
      archived: false
    };
    setSelectedTag(newTag);
    setDrawerOpen(true);
  };

  const handleRowClick: GridEventListener<"rowClick"> = params => {
    //setMessage(`Movie "${params.row.title}" clicked`);
    setSelectedTag(params.row as TagUpdateInput);
    setDrawerOpen(true);
  };
  const handleClearClick = () => {
    setFilterParams({
      ...filterParams,
      searchTerm: ""
    });
  };
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const columns = [
    { field: "name", headerName: "Name", flex: 2, sortable: false },
    { field: "type", headerName: "Type", flex: 2, sortable: false },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleDateString();
      }
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => {
        if (!value) return null;
        return new Date(value).toLocaleDateString();
      }
    },
    {
      field: "archived",
      headerName: "Archived",
      flex: 2,
      sortable: false,
      type: "boolean"
    }
  ];

  return (
    <>
      <Box
        sx={{
          m: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography variant={"h4"} color={"primary"}>
          Tags
        </Typography>
        <Box>
          <IconButton
            onClick={handleDelete}
            disabled={rowSelectionModel?.length === 0}
          >
            <DeleteOutlineIcon />
          </IconButton>
          <Button
            color="primary"
            size="small"
            onClick={onCreateNew}
            variant={"outlined"}
          >
            <AddCircleIcon /> New Tag
          </Button>
        </Box>
      </Box>
      <Box mx={1}>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            label="Search"
            variant="outlined"
            size={"small"}
            margin="normal"
            value={filterParams?.searchTerm}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearClick}>
                  <ClearIcon sx={{ fontSize: 13 }} />
                </IconButton>
              )
            }}
            onChange={handleSearch}
          />
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Autocomplete
                disablePortal
                onChange={(_, newValue) => {
                  setFilterParams({
                    ...filterParams,
                    type: newValue?.id as string
                  });
                }}
                options={
                  [
                    { label: "Global", id: "GLOBAL" },
                    { label: "Interviewer", id: "INTERVIEWER" }
                  ] as {
                    label: string;
                    id: string;
                  }[]
                }
                size={"small"}
                id="type-filter"
                sx={{ width: 200 }}
                renderInput={params => <TextField {...params} label="Type" />}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Autocomplete
                disablePortal
                onChange={(_, newValue) => {
                  setFilterParams({
                    ...filterParams,
                    archived: newValue ? newValue?.id : null
                  });
                }}
                options={
                  [
                    { label: "Yes", id: true },
                    { label: "No", id: false }
                  ] as {
                    label: string;
                    id: boolean;
                  }[]
                }
                size={"small"}
                id="type-filter"
                sx={{ width: 200 }}
                renderInput={params => (
                  <TextField {...params} label="Archived?" />
                )}
              />
            </FormControl>
          </Box>
        </Stack>

        <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
          <DataGrid
            checkboxSelection
            rows={data?.tags?.tags || []}
            columns={columns}
            loading={loading || deleteProcessing}
            density="compact"
            pageSizeOptions={[5, 10, 25, 50, 100]}
            paginationMode="server"
            filterMode="server"
            paginationModel={{
              pageSize: filterParams.perPage,
              page: filterParams.page > 1 ? filterParams.page - 1 : 0
            }}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={newRowSelectionModel => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            keepNonExistentRowsSelected
            onPaginationModelChange={e => {
              setFilterParams({
                ...filterParams,
                page: e.page + 1,
                perPage: e.pageSize
              });
            }}
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
            rowCount={data?.tags?.count || 0}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 }
              }
            }}
          />
        </div>
        <Drawer
          transitionDuration={0}
          anchor={"right"}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {selectedTag && <TagEdit selectedTag={selectedTag as Tag} />}
        </Drawer>
      </Box>
    </>
  );
}

export default TagsList;
