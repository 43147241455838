import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";
import { deleteMethod, get, post, put } from "../../../utilities/restClient";
import { Tag } from "src/@generated/graphql";

const TAGS_QUERY_KEY = "tags-search";

const MODULE_ROUTE = "/tags";

export const useListTags = (
  searchTerm: string
): UseQueryResult<{ count: number; tags: Tag[] }> => {
  return useQuery(
    [TAGS_QUERY_KEY],
    async () => {
      return post(
        `${import.meta.env.REACT_APP_URL_API}${MODULE_ROUTE}/search`,
        {
          searchTerm,
          page: 1,
          perPage: 100
        }
      );
    },
    {
      select: data => data.data
    }
  );
};

export const useFetchTag = (
  id: string | undefined,
  onSuccess?: () => void
): UseQueryResult<Tag> => {
  return useQuery(
    [TAGS_QUERY_KEY, id],
    async () => {
      return get(
        `${import.meta.env.REACT_APP_URL_API}${MODULE_ROUTE}/${id}`,
        {}
      );
    },
    {
      select: data => data.data,
      enabled: !!id,
      onSuccess
    }
  );
};

export const useCreateTag = (
  onCreateTagsSuccess?: () => void,
  onCreateTagsError?: () => void
): UseMutationResult<AxiosResponse, unknown, Tag> => {
  const queryClient = useQueryClient();
  return useMutation(
    (tagEdit: Tag) =>
      post(`${import.meta.env.REACT_APP_URL_API}${MODULE_ROUTE}}`, tagEdit),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TAGS_QUERY_KEY]);
        if (onCreateTagsSuccess) {
          onCreateTagsSuccess();
        }
      },
      onError: () => {
        if (onCreateTagsError) {
          onCreateTagsError();
        }
      }
    }
  );
};

export const useUpdateTag = (
  onUpdateTagSuccess?: () => void,
  onUpdateTagError?: () => void
): UseMutationResult<AxiosResponse, unknown, Tag> => {
  const queryClient = useQueryClient();
  let updateLinkId: string | null = null;

  return useMutation(
    (tagEdit: Tag) => {
      const { id, ...terms } = tagEdit;
      updateLinkId = id || null;
      return put(
        `${import.meta.env.REACT_APP_URL_API}${MODULE_ROUTE}/${id}`,
        terms
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TAGS_QUERY_KEY, updateLinkId]);
        queryClient.invalidateQueries([TAGS_QUERY_KEY]);
        if (onUpdateTagSuccess) {
          onUpdateTagSuccess();
        }
      },
      onError: () => {
        if (onUpdateTagError) {
          onUpdateTagError();
        }
      }
    }
  );
};

export const useDeleteTag = (
  onDeleteTagsSuccess?: () => void,
  onDeleteTagsError?: () => void
) => {
  const queryClient = useQueryClient();
  let deleteLinkId: string | null = null;

  return useMutation(
    (id: string) => {
      deleteLinkId = id || null;
      return deleteMethod(
        `${import.meta.env.REACT_APP_URL_API}${MODULE_ROUTE}/${id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([TAGS_QUERY_KEY, deleteLinkId]);
        queryClient.invalidateQueries([TAGS_QUERY_KEY]);
        if (onDeleteTagsSuccess) {
          onDeleteTagsSuccess();
        }
      },
      onError: () => {
        if (onDeleteTagsError) {
          onDeleteTagsError();
        }
      }
    }
  );
};
