// src/InterviewersList.js

import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import { debounce } from "lodash"; // Import debounce from lodash
import {
  Box,
  Chip,
  Drawer,
  FormControl,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import {
  InterviewersFilterDto,
  InterviewerWithTags,
  Tag
} from "src/@generated/graphql";
import InterviewerEdit from "./interviewer.edit.component";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { GET_INTERVIEWERS } from "./services/interviewers.gql";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import TagsSelect from "../Tags/tags.select.component";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { closeModal, openModal } from "@rooster/ui";
import InterviewersTagUpdate from "./interviewer-tag.update.component";

function InterviewersList() {
  const [filterParams, setFilterParams] = useState<InterviewersFilterDto>({
    page: 1,
    perPage: 25,
    searchTerm: "",
    tags: [],
    departments: []
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedInterviewer, setSelectedInterviewer] =
    useState<InterviewerWithTags | null>(null);

  const { loading, error, data, refetch } = useQuery(GET_INTERVIEWERS, {
    variables: {
      filter: filterParams
    }
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const handleClearClick = () => {
    setFilterParams({
      ...filterParams,
      searchTerm: ""
    });
  };

  const handleRowClick: GridEventListener<"rowClick"> = params => {
    //setMessage(`Movie "${params.row.title}" clicked`);
    setSelectedInterviewer(params.row as InterviewerWithTags);
    setDrawerOpen(true);
  };

  const authUser = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );
  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 500),
    [refetch]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setFilterParams({
      ...filterParams,
      searchTerm
    });
    debouncedRefetch();
  };

  const [selectionModelData, setSelectionModelData] = useState<
    Record<string, InterviewerWithTags>
  >({});

  const handleUpdateTags = () => {
    const scheduleConfirmationModalId = "update-tags-modal";

    openModal({
      title: "Update Tags",
      modalId: scheduleConfirmationModalId,
      maxWidth: "md",
      children: (
        <InterviewersTagUpdate
          interviewerIDs={rowSelectionModel as string[]}
          interviewersModelMap={selectionModelData}
          afterSave={() => {
            closeModal(scheduleConfirmationModalId);
            setRowSelectionModel([]);
          }}
        />
      )
    });
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 2, sortable: false },
    { field: "email", headerName: "Email", flex: 2, sortable: false },
    { field: "title", headerName: "Title", flex: 2, sortable: false },
    {
      field: "tags",
      headerName: "Tags",
      flex: 3,
      sortable: false,
      renderCell: ({ value }) => {
        if (!value) return null;
        return (
          <>
            {value.map((tag: Tag) => (
              <Chip
                sx={{ m: 0.1 }}
                size={"small"}
                key={tag.id}
                label={tag.name}
                variant={"outlined"}
                color={"primary"}
              />
            ))}
          </>
        );
      }
    }
  ];

  // @ts-ignore
  return (
    <>
      <Box m={1}>
        <Box
          sx={{
            m: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography variant={"h4"} color={"primary"}>
            Interviewers
          </Typography>
          <Box>
            <Button
              color="primary"
              size="small"
              disabled={rowSelectionModel?.length === 0}
              onClick={handleUpdateTags}
              variant={"outlined"}
            >
              <AddCircleIcon /> Tag
            </Button>
          </Box>
        </Box>
        <Box mx={1}>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              label="Search"
              variant="outlined"
              size={"small"}
              margin="normal"
              value={filterParams?.searchTerm}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleClearClick}>
                    <ClearIcon sx={{ fontSize: 13 }} />
                  </IconButton>
                )
              }}
              onChange={handleSearch}
            />
            <Box>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TagsSelect
                  value={[]}
                  onChange={function (value: Tag[]): void {
                    setFilterParams({
                      ...filterParams,
                      tags: value.map(tag => tag.id)
                    });
                    debouncedRefetch();
                  }}
                />
              </FormControl>
            </Box>
          </Stack>

          <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
            <DataGrid
              checkboxSelection
              rows={data?.interviewers?.interviewers || []}
              columns={columns ?? []}
              loading={loading}
              density="compact"
              pageSizeOptions={[5, 10, 25, 50, 100]}
              paginationMode="server"
              filterMode="server"
              paginationModel={{
                pageSize: filterParams.perPage ?? 25,
                page:
                  (filterParams?.page ?? 1) > 1
                    ? (filterParams?.page ?? 1) - 1
                    : 0
              }}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={ids => {
                setRowSelectionModel(ids);
                ids.forEach(id => {
                  const selData = data?.interviewers?.interviewers.find(
                    i => i.id === id
                  );
                  if (!selData) return;
                  setSelectionModelData({
                    ...(selectionModelData ?? {}),
                    [id as string]: selData
                  } as Record<string, InterviewerWithTags>);
                });
              }}
              keepNonExistentRowsSelected
              onPaginationModelChange={e => {
                setFilterParams({
                  ...filterParams,
                  page: e.page + 1,
                  perPage: e.pageSize
                });
              }}
              disableRowSelectionOnClick
              onRowClick={handleRowClick}
              rowCount={data?.interviewers?.count || 0}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 }
                }
              }}
            />
          </div>
          <Drawer
            transitionDuration={0}
            anchor={"right"}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            {selectedInterviewer && (
              <InterviewerEdit
                afterSave={() => {
                  setDrawerOpen(false);
                }}
                selectedInterviewer={selectedInterviewer}
              />
            )}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

export default InterviewersList;
