import {
  Button,
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Confirm,
  Eventcalendar,
  formatDate,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscEventCreatedEvent,
  MbscEventCreateFailedEvent,
  MbscEventDeletedEvent,
  MbscEventUpdatedEvent,
  MbscEventUpdateFailedEvent,
  MbscResource,
  momentTimezone,
  setOptions,
  Toast
} from "@mobiscroll/react";
import { Pagination } from "@mui/material";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "./interviewers.availability.css";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { InterviewersFilterDto } from "../../../@generated/graphql";
import { useQuery } from "@apollo/client";
import { GET_INTERVIEWERS } from "../../Interviewers/services/interviewers.gql";
import { useGetAvailabilityForInterviewers } from "./hooks";
import { n } from "vitest/dist/reporters-MmQN-57K";
import { AutobookViewApiPayload } from "@rooster/types";
import { useSelector } from "react-redux";

momentTimezone.moment = moment;

setOptions({
  theme: "ios",
  themeVariant: "light"
});

const defaultEvents: MbscCalendarEvent[] = [
  {
    start: "2024-08-12T13:00",
    end: "2024-08-12T15:00",
    title: "General orientation",
    color: "#1ad404"
  }
];

const InterviewersAvailability: FC = () => {
  const [myEvents, setMyEvents] = useState<MbscCalendarEvent[]>([
    defaultEvents
  ]);
  const [isToastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [tempEvent, setTempEvent] = useState<MbscCalendarEvent>();
  const [isNewEvent, setIsNewEvent] = useState<boolean>(false);
  const [interviewerFilterParams, setInterviewerFilterParams] =
    useState<InterviewersFilterDto>({
      page: 1,
      perPage: 12,
      searchTerm: "",
      tags: [],
      departments: []
    });
  const { loading: interviewersLoading, data: interviewersResponse } = useQuery(
    GET_INTERVIEWERS,
    {
      variables: {
        filter: interviewerFilterParams
      }
    }
  );
  const authUser = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );

  const [availabilityFilterParams, setAvailabilityFilterParams] = useState<{
    client: string;
    queryWindow: AutobookViewApiPayload["queryWindows"][0];
    requesterEmail: AutobookViewApiPayload["requesterEmail"];
    queryResources: AutobookViewApiPayload["queryResources"];
    tzid: AutobookViewApiPayload["responseFormat"]["tzid"];
    page: number;
  } | null>(null);

  const queryWindow = {
    timeMin: new Date().toISOString(),
    timeMax: new Date(Date.now() + 12096e5).toISOString()
  };
  const { data: availabilityData, isFetching: availabilityLoading } =
    useGetAvailabilityForInterviewers(availabilityFilterParams);

  useEffect(() => {
    if (
      interviewersResponse &&
      interviewersResponse?.interviewers?.interviewers?.length > 0 &&
      authUser?.client
    ) {
      setAvailabilityFilterParams({
        ...(availabilityFilterParams ?? {}),
        client: authUser.client,
        queryWindow,
        queryResources: interviewersResponse?.interviewers?.interviewers?.map(
          interviewer => ({
            type: "interviewer",
            value: interviewer.email,
            provider: "google"
          })
        ),
        requesterEmail: authUser.email,
        tzid: moment.tz.guess(),
        page: interviewerFilterParams?.page ?? 1
      });
    }
  }, [interviewersResponse?.interviewers, authUser]);

  const [page, setPage] = useState(1);

  const resources: MbscResource[] = useMemo(() => {
    const interviewers = interviewersResponse?.interviewers.interviewers;
    if (availabilityData && interviewers) {
      return interviewers?.map(interviewer => {
        const availability = availabilityData.queryWindowsResult.find(
          x => x.participant === interviewer.email
        );
        const timezone =
          availability?.interviewerPreference?.availability?.timezone ?? "utc";
        return {
          id: interviewer.email,
          name: interviewer.name,
          email: interviewer.email,
          timezone,
          utcOffset: `UTC ${moment().tz(timezone).format("Z")}`,
          organizer: false
        };
      });
    }
    return [];
  }, [availabilityData, interviewersResponse?.interviewers]);

  const myView = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        type: "week",
        timeCellStep: 30,
        timeLabelStep: 1440
      }
    }),
    []
  );

  const details = useMemo(() => {
    const colors: MbscCalendarColor[] = [];
    const queryWindowDates: string[] = [];
    let currentDate = moment(queryWindow.timeMin).startOf("day");
    const queryWindowEndDate = moment(queryWindow.timeMax).startOf("day");

    while (currentDate.isSameOrBefore(queryWindowEndDate)) {
      queryWindowDates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    queryWindowDates.forEach(windowDate => {
      availabilityData?.queryWindowsResult?.forEach(queryWindow => {
        const exists = queryWindow.availabilityView?.some(
          view => moment(view.date).format("YYYY-MM-DD") === windowDate
        );
        if (!exists) {
          colors.push({
            date: new Date(windowDate),
            title: "No availability",
            background: "#ffbaba4d",
            resource: queryWindow.participant
          });
        }
      });
    });

    availabilityData?.queryWindowsResult?.forEach(queryWindow => {
      queryWindow.availabilityView?.forEach(view => {
        const date = new Date(view.date);

        // Iterate for each 30 mins for the date
        const startOfDay = moment(date).startOf("day");
        const endOfDay = moment(date).endOf("day");

        while (startOfDay.isBefore(endOfDay)) {
          const slotStartHour = startOfDay.hour();
          const slotStartMinutes = startOfDay.minutes();
          const slotStart = startOfDay.clone();
          const slotEnd = startOfDay.add(30, "minutes");
          const data = view.availability?.find(
            slot =>
              slot.hour === slotStartHour && slot.minute === slotStartMinutes
          );
          const slotStartUTC = slotStart.clone().utc();
          const participantTimezone =
            queryWindow.interviewerPreference?.availability?.timezone ?? "utc";

          const slotStartParticipantTime = slotStartUTC
            .clone()
            .tz(participantTimezone)
            .format("hh:mm A");

          colors.push({
            start: slotStart.toDate(),
            end: slotEnd.toDate(),
            title: slotStartParticipantTime,
            background: data?.available
              ? "#c9e8d1"
              : data?.outsideOfWorkingHours
              ? "#f7f7bb4d"
              : "#ffbaba4d",
            resource: queryWindow.participant
          });
        }
      });
    });

    return { colors };
  }, [resources, availabilityData]);

  const myScheduleEvent = useCallback((data: MbscCalendarEventData) => {
    const start = (data.startDate as MyDate).clone();
    const end = (data.endDate as MyDate).clone();

    start.setTimezone(data.currentResource!.timezone);
    end.setTimezone(data.currentResource!.timezone);

    return (
      <div
        className="md-meeting-planner-cont"
        style={{ background: data.color }}
      >
        <div className="md-meeting-planner-wrapper">
          <div className="md-meeting-planner-title">{data.title}</div>
          <div className="md-meeting-planner-time">
            {formatDate("hh:mm A", start)} - {formatDate("hh:mm A", end)}
          </div>
        </div>
      </div>
    );
  }, []);

  const myHeader = () => (
    <>
      <CalendarNav />
      <div className="md-meeting-planner-header">
        <div className="md-meeting-planner-zone md-meeting-planner-work">
          non working hours
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-flex">
          available
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-off">
          booked
        </div>
        <CalendarPrev />
        <CalendarToday />
        <CalendarNext />
      </div>
    </>
  );

  const myResource = (resource: MbscResource) => (
    <div className="md-meeting-participant-cont">
      <div className="md-meeting-participant-name">{resource.name}</div>
      <div>
        {resource.organizer && <span>Organizer </span>}
        <span className="md-meeting-participant-offset">
          {resource.utcOffset}
        </span>
      </div>
      <img
        className="md-meeting-participant-avatar"
        src={resource.img}
        alt="avatar"
      />
    </div>
  );

  const myDefaultEvent = useCallback(
    () => ({
      resource: [1, 2, 3, 4, 5, 6]
    }),
    []
  );

  const handleEventCreated = useCallback(
    (args: MbscEventCreatedEvent) => {
      setMyEvents([...myEvents, args.event]);
      setTimeout(() => {
        setToastMessage("Event created");
        setToastOpen(true);
      });
    },
    [myEvents]
  );

  const handleEventUpdated = useCallback(
    (args: MbscEventUpdatedEvent) => {
      const index = myEvents.findIndex(x => x.id === args.event.id);
      const newEventList = [...myEvents];

      newEventList.splice(index, 1, args.event);
      setMyEvents(newEventList);
      setTimeout(() => {
        setToastMessage("Event updated");
        setToastOpen(true);
      });
    },
    [myEvents]
  );

  const handleEventDeleted = useCallback(
    (args: MbscEventDeletedEvent) => {
      setMyEvents(myEvents.filter(item => item.id !== args.event.id));
    },
    [myEvents]
  );

  const createUpdateEvent = useCallback(
    (event: MbscCalendarEvent, isNew: boolean) => {
      setTempEvent(event);
      setIsNewEvent(isNew);
      setConfirmOpen(true);
    },
    []
  );

  const handleEventCreateFailed = useCallback(
    (args: MbscEventCreateFailedEvent) => {
      createUpdateEvent(args.event, true);
    },
    [createUpdateEvent]
  );

  const handleEventUpdateFailed = useCallback(
    (args: MbscEventUpdateFailedEvent) => {
      createUpdateEvent(args.event, false);
    },
    [createUpdateEvent]
  );

  const handleCloseToast = useCallback(() => setToastOpen(false), []);

  const handleConfirmClose = useCallback(
    (res: boolean) => {
      if (res) {
        if (isNewEvent) {
          setMyEvents([...myEvents, tempEvent!]);
        } else {
          const index = myEvents.findIndex(x => x.id === tempEvent!.id);
          const newEventList = [...myEvents];

          newEventList.splice(index, 1, tempEvent!);
          setMyEvents(newEventList);
        }
        setToastMessage(isNewEvent ? "Event created" : "Event updated");
        setToastOpen(true);
      }
      setConfirmOpen(false);
    },
    [isNewEvent, myEvents, tempEvent]
  );
  // const onEventCreate = useCallback((args, inst) => {
  //   args.event.resource = [1, 2, 3, 4, 5, 6];
  // }, []);
  const [myResources, setMyResources] = useState<MbscResource[]>(resources);

  useEffect(() => {
    if (resources?.length > 0) {
      setMyResources([...fixedResources, ...resources]);
    }
  }, [resources]);

  const [fixedNr, setFixedNr] = useState<number>(0);
  const [fixedResources, setFixedResources] = useState<MbscResource[]>([]);

  const toggleComparison = useCallback(
    (resource: MbscResource) => {
      const isFixed = resource.fixed;
      const origResource = myResources.find(r => r.id === resource.id)!;
      let newFixedResources = [];
      let newFixedNr = fixedNr;

      if (!isFixed) {
        origResource!.fixed = true;
        newFixedResources = [...fixedResources, origResource];
        newFixedNr++;
      } else {
        origResource!.fixed = false;
        newFixedResources = fixedResources.filter(r => r.id !== resource.id);
        newFixedNr--;
      }

      const newResources = [...newFixedResources];
      myResources.forEach(r => {
        if (!r.fixed) {
          newResources.push(r);
        }
      });

      setMyResources(newResources);
      setFixedResources(newFixedResources);
      setFixedNr(newFixedNr);

      if (newFixedNr > 2) {
        setToastOpen(true);
      }
    },
    [fixedNr, fixedResources, myResources]
  );
  const handlePageChange = (_, value: number) => {
    setPage(value);
    setInterviewerFilterParams({
      ...interviewerFilterParams,
      page: value
    });
  };
  const customResource = useCallback(
    (r: MbscResource) => (
      <div className="md-compare-resource mbsc-flex mbsc-align-items-center mbsc-justify-content-between">
        <div className="md-meeting-participant-name">{r.name}</div>
        <div>
          {r.organizer && <span>Organizer </span>}
          <span className="md-meeting-participant-offset">{r.utcOffset}</span>
        </div>
        {r.fixed || (!r.fixed && fixedNr <= 2) ? (
          <Button
            className="md-compare-button"
            color={r.fixed ? "danger" : "success"}
            variant="outline"
            onClick={() => toggleComparison(r)}
          >
            {r.fixed ? "Remove" : "Compare"}
          </Button>
        ) : (
          ""
        )}
      </div>
    ),
    [fixedNr, toggleComparison]
  );

  return (
    <>
      {(availabilityLoading || interviewersLoading) && <div>Loading...</div>}
      {!availabilityLoading && !interviewersLoading && (
        <>
          {" "}
          <Eventcalendar
            timezonePlugin={momentTimezone}
            dataTimezone={moment.tz.guess()}
            displayTimezone={moment.tz.guess()}
            clickToCreate={true}
            dragToCreate={true}
            dragToMove={true}
            dragToResize={true}
            dragTimeStep={60}
            height={600}
            view={myView}
            data={myEvents}
            resources={myResources}
            colors={details.colors}
            invalid={details.invalid}
            extendDefaultEvent={myDefaultEvent}
            renderScheduleEvent={myScheduleEvent}
            renderHeader={myHeader}
            renderResource={customResource}
            onEventCreated={handleEventCreated}
            onEventUpdated={handleEventUpdated}
            onEventDeleted={handleEventDeleted}
            onEventCreateFailed={handleEventCreateFailed}
            onEventUpdateFailed={handleEventUpdateFailed}
            min={new Date()}
            max={new Date(Date.now() + 12096e5)}
          />
          <Pagination
            count={Math.ceil(
              (interviewersResponse?.interviewers?.count ?? 0) / 12
            )}
            size="small"
            page={page}
            onChange={handlePageChange}
          />
        </>
      )}

      <Toast
        message={toastMessage}
        isOpen={isToastOpen}
        onClose={handleCloseToast}
      />
      <Confirm
        isOpen={isConfirmOpen}
        title="Are you sure you want to proceed?"
        message="It looks like someone from the team won't be able to join the meeting."
        okText="Yes"
        cancelText="No"
        onClose={handleConfirmClose}
      />
    </>
  );
};
export default InterviewersAvailability;

interface MyDate extends Date {
  clone(): MyDate;
  setTimezone(timezone: MyDate): void;
}
