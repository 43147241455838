// Generate for component InterviewerEdit
import React, { useState } from "react";
import { Button, FormControl, Stack, Typography } from "@mui/material";
import { InterviewerWithTags, Tag } from "src/@generated/graphql";
import { useTranslation } from "react-i18next";
import TagsSelect from "../Tags/tags.select.component";
import { useMutation } from "@apollo/client";
import {
  GET_INTERVIEWERS,
  UPDATE_MANY_INTERVIEWERS
} from "./services/interviewers.gql";

interface InterviewersTagUpdateProps {
  interviewerIDs: string[];
  interviewersModelMap: Record<string, InterviewerWithTags>;
  afterSave: () => void;
}

const InterviewersTagUpdate: React.FC<InterviewersTagUpdateProps> = ({
  interviewerIDs,
  interviewersModelMap,
  afterSave
}) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [
    updateManyInterviewers,
    { loading: interviewerUpdateProcessing, error: updateInterviewerError }
  ] = useMutation(UPDATE_MANY_INTERVIEWERS, {
    refetchQueries: [GET_INTERVIEWERS]
  });

  const onSave = () => {
    updateManyInterviewers({
      variables: {
        where: {
          id: { in: interviewerIDs }
        },
        data: {
          tagIds: selectedTags.map(tag => tag.id)
        }
      }
    }).then(() => {
      afterSave && afterSave();
    });
  };
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={2} flex={1} m={1} width={600}>
      <Typography variant="h5">
        {" "}
        {interviewerIDs
          ?.map(id => interviewersModelMap[id]?.name)
          .join(", ")}{" "}
      </Typography>

      {/* Meeting duration */}
      <FormControl variant="outlined">
        <TagsSelect
          value={[]}
          allowCreate={true}
          onChange={(values: Tag[]) => {
            setSelectedTags([...values]);
          }}
        />
      </FormControl>
      <Button
        type="submit"
        disabled={interviewerUpdateProcessing}
        variant="contained"
        color="primary"
        onClick={onSave}
      >
        Save
      </Button>
    </Stack>
  );
};

export default InterviewersTagUpdate;
