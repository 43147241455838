// Generate for component InterviewerEdit
import React, { useState } from "react";
import { Button, FormControl, Stack, Typography } from "@mui/material";
import { InterviewerWithTags, Tag } from "src/@generated/graphql";
import { useTranslation } from "react-i18next";
import TagsSelect from "../Tags/tags.select.component";
import { useMutation } from "@apollo/client";
import {
  GET_INTERVIEWERS,
  UPDATE_INTERVIEWER
} from "./services/interviewers.gql";

interface InterviewEditProps {
  selectedInterviewer: InterviewerWithTags;
  afterSave: () => void;
}

const InterviewerEdit: React.FC<InterviewEditProps> = ({
  selectedInterviewer,
  afterSave
}) => {
  const [interviewer, setInterviewer] =
    useState<InterviewerWithTags>(selectedInterviewer);

  const [
    updateInterviewer,
    { loading: interviewerUpdateProcessing, error: updateInterviewerError }
  ] = useMutation(UPDATE_INTERVIEWER, {
    refetchQueries: [GET_INTERVIEWERS]
  });

  const onSave = () => {
    updateInterviewer({
      variables: {
        id: interviewer.id,
        data: {
          tagIds: interviewer?.tags?.map(tag => tag.id) ?? []
        }
      }
    }).then(() => {
      afterSave && afterSave();
    });
  };
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={2} flex={1} m={1} width={600}>
      <Typography variant="h5"> {interviewer.name} </Typography>

      {/* Meeting duration */}
      <FormControl variant="outlined">
        <TagsSelect
          value={selectedInterviewer.tags ?? []}
          allowCreate={true}
          onChange={(values: Tag[]) => {
            setInterviewer({ ...interviewer, tags: values });
          }}
        />
      </FormControl>
      <Button
        type="submit"
        disabled={interviewerUpdateProcessing}
        variant="contained"
        color="primary"
        onClick={onSave}
      >
        Save
      </Button>
    </Stack>
  );
};

export default InterviewerEdit;
