import { useQuery, UseQueryResult } from "react-query";
import {
  AutobookViewApiPayload,
  AutobookViewApiResponse
} from "@rooster/types";
import { post } from "../../../utilities/restClient";
import { GCF } from "../../../constants/dbQuery";
const QUERY_KEY = "interviewers-availablility";

export const useGetAvailabilityForInterviewers = (
  params: {
    client: string;
    queryWindow: AutobookViewApiPayload["queryWindows"][0];
    requesterEmail: AutobookViewApiPayload["requesterEmail"];
    queryResources: AutobookViewApiPayload["queryResources"];
    tzid: AutobookViewApiPayload["responseFormat"]["tzid"];
    page: number;
  } | null
): UseQueryResult<AutobookViewApiResponse> => {
  const { client, queryWindow, requesterEmail, queryResources, tzid, page } =
    params ?? {};
  return useQuery(
    [QUERY_KEY + page],
    async () => {
      return post(`${GCF.BASE_URL}/queryAvailability/view/people`, {
        client,
        requesterEmail,
        availabilityInterval: {
          value: 30,
          intervalStartsFrom: "startOfDay"
        },
        eventDuration: 30,
        queryWindows: [queryWindow],
        queryResources: queryResources,
        responseFormat: {
          showTentativeAsBusy: true,
          tzid,
          hideUnavailableSlot: false,
          hideParticipantsInSlot: true,
          removeMultiDaySlot: false,
          type: "slot"
        },
        fetchMethod: ["freebusy.api", "events.api"],
        makeParticipantAsBusyWhenUnableToFetchEvents: true,
        skipInterviewerPreferencesSync: true
      });
    },
    {
      enabled: !!params,
      select: data => data.data
    }
  );
};
