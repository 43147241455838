// Generate for component TagEdit
import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Tag } from "src/@generated/graphql";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_TAG, GET_TAGS, UPDATE_TAG } from "./services/tags.gql";
import { useSelector } from "react-redux";

interface TagEditProps {
  selectedTag: Tag;
}

const TagEdit: React.FC<TagEditProps> = ({ selectedTag }) => {
  const { client, id } = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );
  const [tag, setTag] = useState<TagEditProps["selectedTag"]>(
    selectedTag ?? {
      clientId: client,
      name: null,
      type: null,
      archived: false,
      id: undefined
    }
  );
  const [createTag, { data, loading: tagCreateProcessing, error }] =
    useMutation(CREATE_TAG, {
      refetchQueries: [GET_TAGS]
    });

  const [updateTag, { loading: tagUpdateProcessing, error: updateTagError }] =
    useMutation(UPDATE_TAG, {
      refetchQueries: [GET_TAGS]
    });

  const onSave = () => {
    if (tag && tag.name && tag.type) {
      if (tag.id) {
        updateTag({
          variables: {
            id: tag.id,
            data: {
              name: tag.name
            }
          }
        });
      } else {
        createTag({
          variables: {
            input: {
              clientId: tag.clientId || client,
              name: tag.name,
              type: tag.type,
              archived: false,
              createdBy: tag.createdBy ?? id
            }
          }
        });
      }
    }
  };
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={2} flex={1} m={1} width={600}>
      <Typography variant="h5">
        {tag?.id ? "Edit Tag" : "Create New Tag"}
      </Typography>
      <Stack m={2} spacing={2}>
        {/* Meeting duration */}
        <FormControl variant="outlined">
          <FormLabel id="tag-name" sx={{ fontSize: 12 }}>
            Name
          </FormLabel>
          <TextField
            size={"small"}
            value={tag?.name}
            onChange={e => setTag({ ...tag, name: e.target.value })}
          />
        </FormControl>
        <FormControl variant="outlined">
          <FormLabel id="tag-type" sx={{ fontSize: 12 }}>
            Type
          </FormLabel>
          <Select
            disabled={!!tag?.id}
            size={"small"}
            value={tag?.type}
            defaultValue="INTERVIEWER"
            onChange={e => setTag({ ...tag, type: e.target.value })}
          >
            <MenuItem value="GLOBAL">Global</MenuItem>
            <MenuItem value="INTERVIEWER">Interviewer</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={tagUpdateProcessing || tagCreateProcessing}
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default TagEdit;
